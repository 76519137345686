/* eslint-disable vue/return-in-computed-property */
<template>
  <v-app>
    <div
      v-bind:class="{
        bgSvg: !$vuetify.theme.dark,
        bgSvgDark: $vuetify.theme.dark
      }"
    >
      <v-flex
        class="backDropper pa-4 ma-0"
        v-bind:style="{
          'color:rgb(255, 255, 255,0.5)': !$vuetify.theme.dark,
          'color:rgb(0,0,0)': $vuetify.theme.dark
        }"
      >
        <v-dialog
          v-model="dialog"
          scrollable
          :hide-overlay="false"
          overlay-opacity="0.8"
          max-width="100vh"
        >
          <v-card class="pa-6 text-center">
            {{ dialogMessage }}
          </v-card>
        </v-dialog>
        <v-dialog v-model="group" max-width="70vw" min-width="70vw">
          <v-card class="pa-4 px-9">
            <v-row
              ><v-col sm="12" md="12"
                ><v-select
                  :items="Department"
                  label="Department"
                  v-model="dept"
                ></v-select>
              </v-col>
              <v-col sm="12" md="12" v-if="!['SMMU', 'CMMU'].includes(dept)">
                <v-select
                  v-model="dist"
                  :items="District"
                  label="District "
                ></v-select></v-col
            ></v-row>
            <div class="text-center">
              <v-btn
                rounded
                outlined
                dark
                class="primary"
                color=" accent-3 white--text"
                @click="groupNoRet()"
                >Retrieve</v-btn
              >
            </div>
          </v-card>
        </v-dialog>
        <v-card class="ma-0  pa-6 " min-height="85vh">
          <v-row>
            <v-col cols="12" md="6">
              <validation-observer class="ma-0 pa-0" ref="smsForm">
                <validation-provider
                  class="ma-0 pa-0"
                  :rules="{ regex: /^[2-9]\d{2}[2-9]\d{2}\d{4}$/ }"
                  v-slot="{ errors }"
                  name="Enter Phone No"
                >
                  <v-text-field
                    name="Phone No"
                    :error-messages="errors"
                    label="I thawn duhna Number dah rawh"
                    v-model="phoneNo"
                    prepend-icon="fas fa-phone"
                    @keyup="watchPhoneNo(errors, phoneNo)"
                  >
                  </v-text-field>
                </validation-provider>
              </validation-observer>
              <validation-observer
                class="ma-0 pa-0"
                ref="smsForm"
                v-slot="{ invalid }"
              >
                <validation-provider
                  name="Thu thawn chhuah tur"
                  :rules="{ maxLength: 114, minSpace: true, required: true }"
                  v-slot="{ errors }"
                >
                  <v-textarea
                    :error-messages="errors"
                    outlined
                    counter
                    v-model="msg"
                    name="input-7-1"
                    label="Message thawn tur type rawh le"
                    hint="Thumal pali aia tam ngei ngei tur a ni"
                  ></v-textarea>
                </validation-provider>

                <div class="text-center">
                  <v-btn
                    elevation="0"
                    class="primary"
                    @click="send()"
                    :disabled="invalid"
                    >Submit
                  </v-btn>
                </div>
              </validation-observer>
            </v-col>
            <v-col>
              <v-toolbar color="primary" dark>
                <v-app-bar-nav-icon
                  v-on:click="displayGroup"
                ></v-app-bar-nav-icon>

                <v-toolbar-title>List of Phone No</v-toolbar-title>

                <v-spacer></v-spacer>
                <v-btn
                  rounded
                  outlined
                  color=" accent-3 white--text"
                  v-on:click="phoneNoArr = []"
                >
                  CLEAR
                </v-btn>
              </v-toolbar>

              <v-list
                outlined
                v-if="phoneNoArr"
                style="overflow:scroll;overflow-x:hidden"
                max-height="69vh"
                min-height="69vh"
              >
                <v-list-item-group>
                  <template v-for="(item, index) in phoneNoArr">
                    <v-list-item :key="index">
                      <v-list-item-content>
                        <v-list-item-title class="primary--text .text">{{
                          item.Name
                        }}</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ item.Contact }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon>
                          <v-icon @click="deletePhoneNo(item)"
                            >fas fa-trash</v-icon
                          >
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
        </v-card>
      </v-flex>
    </div>
  </v-app>
</template>
<script>
import axios from 'axios'
import { mapState } from 'vuex'
import firebase from 'firebase/app'
import 'firebase/storage'
import { required, regex, numeric, max } from 'vee-validate/dist/rules'
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from 'vee-validate'
setInteractionMode('eager')
extend('phoneNo', {
  ...numeric,
  message: 'Digit chiah tah hian a enter theih'
})
extend('required', {
  ...required,
  message: '{_field_} a ruak theilo'
})
extend('maxLength', {
  ...max,
  message: 'Thu thawn tur hi character 114 aiin a tam theilo'
})
extend('minSpace', {
  validate (value) {
    return value.split(' ').length > 3
  },

  message: 'Thu thawn tur hi thumal pali tal tur a ni'
})
extend('regex', regex)
export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data () {
    return {
      dialogMessage: '',
      dialog: false,
      dept: '',
      dist: '',
      groupId: {
        smmu: 1206786,
        cmmu: 1206787,
        alf_aizawl: 1204664,
        alf_champhai: 1204672,
        alf_hnahthial: 1204673,
        alf_khawzawl: 1204674,
        alf_kolasib: 1204667,
        alf_lawngtlai: 1204666,
        alf_lunglei: 1204668,
        alf_mamit: 1204669,
        alf_saiha: 1204670,
        alf_saitual: 1204675,
        alf_serchhip: 1204671,
        clf_aizawl: 1204677,
        clf_champhai: 1204685,
        clf_hnahthial: 1204684,
        clf_khawzawl: 1204686,
        clf_kolasib: 1204678,
        clf_lawngtlai: 1204679,
        clf_lunglei: 1204680,
        clf_mamit: 1204681,
        clf_saiha: 1204682,
        clf_saitual: 1204687,
        clf_serchhip: 1204683,
        estp_aizawl: 1204710,
        estp_champhai: 1204718,
        estp_hnahthial: 1204717,
        estp_khawzawl: 1204719,
        estp_kolasib: 1204711,
        estp_lawngtlai: 1204712,
        estp_lunglei: 1204713,
        estp_mamit: 1204714,
        estp_saiha: 1204715,
        estp_saitual: 1204720,
        estp_serchhip: 1204716,
        sep_aizawl: 1204721,
        sep_champhai: 1204728,
        sep_hnahthial: 1204729,
        sep_khawzawl: 1204730,
        sep_kolasib: 1204722,
        sep_lawngtlai: 1204723,
        sep_lunglei: 1204724,
        sep_mamit: 1204725,
        sep_saiha: 1204726,
        sep_saitual: 1204731,
        sep_serchhip: 1204727,
        shg_aizawl: 1204655,
        shg_champhai: 1204662,
        shg_hnahthial: 1204676,
        shg_kolasib: 1204657,
        shg_lawngtlai: 1204658,
        shg_lunglei: 1204659,
        shg_mamit: 1204656,
        shg_saiha: 1204660,
        shg_saitual: 1204665,
        shg_serchhip: 1204661,
        sma_aizawl: 1204688,
        sma_champhai: 1204696,
        sma_hnahthial: 1204695,
        sma_khawzawl: 1204697,
        sma_lawngtlai: 1204690,
        sma_lunglei: 1204691,
        sma_mamit: 1204692,
        sma_saiha: 1204693,
        sma_saitual: 1204698,
        sma_serchhip: 1204694,
        smc_aizawl: 1204699,
        smc_champhai: 1204706,
        smc_hnahthial: 1204707,
        smc_khawzawl: 1204709,
        smc_kolasib: 1204700,
        smc_lawngtlai: 1204701,
        smc_lunglei: 1204702,
        smc_mamit: 1204703,
        smc_saiha: 1204704,
        smc_saitual: 1204708,
        smc_serchhip: 1204705,
        susv_aizawl: 1204732,
        susv_champhai: 1204739,
        susv_hnahthial: 1204741,
        susv_khawzawl: 1204742,
        susv_kolasib: 1204733,
        susv_lawngtlai: 1204734,
        susv_lunglei: 1204735,
        susv_mamit: 1204736,
        susv_saiha: 1204738,
        susv_saitual: 1204740,
        susv_serchhip: 1204737
      },
      Department: [
        'SHG',
        'ALF',
        'CLF',
        'SMA',
        'SMC',
        'ESTP',
        'SEP',
        'SUSV',
        'CMMU',
        'SMMU'
      ],
      District: [
        'Aizawl',
        'Kolasib',
        'Lawngtlai',
        'Lunglei',
        'Mamit',
        'Saiha ',
        'Serchhip',
        'Champhai',
        'Hnahthial',
        'Khawzawl',
        'Saitual'
      ],
      group: false,
      msg: '',
      phoneNo: '',
      phoneNoArr: [],
      fileProg: [],
      fileUpload: null,
      progress: 0,
      arr: []
    }
  },
  methods: {
    checkButton (value) {
      return value && this.phoneNoArr.length > 0
    },
    async groupNoRet () {
      let api
      await Object.keys(this.userInfo).forEach(async e => {
        if (e.startsWith('fie')) {
          api = e
        }
      })
      this.phoneNoArr = []
      let res

      if (this.dist) {
        res = `${this.dept.toLowerCase()}_${this.dist.toLowerCase()}`
      } else {
        res = `${this.dept.toLowerCase()}`
      }

      const groupid = this.groupId[res]
      const apiKey = Buffer.from(this.userInfo[api], 'base64').toString('ascii')
      var url = 'https://api.textlocal.in/get_contacts/?'
      var newest = `${url}apiKey=${apiKey}&group_id=${groupid}`
      axios
        .get(newest)
        .then(res => {
          res.data.contacts.forEach(e => {
            this.phoneNoArr.push({
              Name: e['first_name'],
              Contact: e.number
            })
          })
        })
        .catch(function (error) {
          this.$store.commit('changeError', error)
        })
    },
    displayGroup () {
      this.group = true
    },
    deletePhoneNo (item) {
      let arr = this.phoneNoArr
      let val = item
      this.phoneNoArr = arr.filter(e => e != val)
    },
    watchPhoneNo (err, phone) {
      let isnum = /^\d+$/.test(phone)
      if (err.length === 0 && isnum && phone.length === 10) {
        if (this.phoneNoArr.indexOf(`91${phone}`) !== 0) {
          this.phoneNoArr.push({ Name: '', Contact: `91${phone}` }),
            (this.phoneNo = '')
        } else this.$store.commit('changeError', 'Double Entry')
      }
    },
    async send () {
      firebase
      let numberFiltered = []
      this.phoneNoArr.forEach(e => {
        if (!numberFiltered.includes(e.Contact)) {
          numberFiltered.push(e.Contact)
        }
       
      })
  
      this.dialog = true
      this.dialogMessage = 'Sending your message please wait ... '
      let api
      await Object.keys(this.userInfo).forEach(async e => {
        if (e.startsWith('fie')) {
          api = e
        }
      })

      const apiKey = Buffer.from(this.userInfo[api], 'base64').toString('ascii')

      const message = `UD&PA atanga thuchhuah: ${this.msg}`

      var url = 'https://api.textlocal.in/send/?'
      var newest = `${url}apiKey=${apiKey}&numbers=${numberFiltered.toString()}&sender=UDnPA&message=${encodeURIComponent(
        message
      )}`

      axios
        .post(newest)
        .then(response => {
          if (response) {
            if (response.data.errors) this.$store = response.data.errors
            let resRecipient = []

            response.data.messages.forEach(element =>
              resRecipient.push(element)
            )
            resRecipient = resRecipient.map(element => element.recipient)
            this.dialogMessage = `${response.data.num_messages}message(s) send ${response.data.status} with cost ${response.data.cost} and Batch ID = ${response.data.batch_id} by ${response.data.message.sender}. Remaining balance is ${response.data.balance}`

            firebase
              .firestore()
              .collection('smsSend')
              .doc('smsDetails')
              .update({
                smsList: firebase.firestore.FieldValue.arrayUnion(response.data)
              })
              .then((this.msg = ''))
              .catch(error => {
                this.$store.commit('changeError', error)
              })
          }
        })
        .catch(function () {
        })
    }
  },
  created () {
    if (this.$route.params.recipient.split('-').length === 2)
      this.phoneNoArr.push({
        Name: this.$route.params.recipient.split('-')[0],
        Contact: `91${this.$route.params.recipient.split('-')[1]}`
      })


    if (
      isNaN(this.$route.params.recipient.split('-')[1]) &&
      this.$route.params.recipient !== 'SMMU_CMMU'
    ) {
      this.dept = this.$route.params.recipient.split('_')[0]
      if (
        !['CMMU', 'SMMU'].includes(this.$route.params.recipient.split('_')[0])
      )
        this.dist = this.$route.params.recipient.split('_')[1] ?? ''
      this.dept = this.$route.params.recipient.split('_')[0] ?? ''
      this.groupNoRet()
    }
  },

  computed: {
    ...mapState(['userInfo'])
  }
}
</script>
<style scoped>
.rounded {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}
.bgSvg {
  min-height: 100vh;

  background-color: var(--v-secondary-darken--20);
  background-repeat: repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='111' height='111' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='100' y1='33' x2='100' y2='-3'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='100' y1='135' x2='100' y2='97'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23cacaca' fill-opacity='0.46'%3E%3Crect x='100' width='100' height='100'/%3E%3Crect y='100' width='100' height='100'/%3E%3C/g%3E%3Cg fill-opacity='0.46'%3E%3Cpolygon fill='url(%23a)' points='100 30 0 0 200 0'/%3E%3Cpolygon fill='url(%23b)' points='100 100 0 130 0 100 200 100 200 130'/%3E%3C/g%3E%3C/svg%3E");
}
.bgSvgDark {
  min-height: 100vh;

  background-color: #07070748;
  background-repeat: repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='100' y1='33' x2='100' y2='-3'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='100' y1='135' x2='100' y2='97'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23060606' %3E%3Crect x='100' width='100' height='100'/%3E%3Crect y='100' width='100' height='100'/%3E%3C/g%3E%3Cg fill-opacity='1'%3E%3Cpolygon fill='url(%23a)' points='100 30 0 0 200 0'/%3E%3Cpolygon fill='url(%23b)' points='100 100 0 130 0 100 200 100 200 130'/%3E%3C/g%3E%3C/svg%3E");
}
.backDropper {
  min-height: 100vh;

  backdrop-filter: blur(15px);
}
</style>
